import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import {
  Typography,
  Paper,
  Dialog,
  IconButton,
} from '@material-ui/core';
import {
  Search,
  ArrowDownward,
  Clear,
  FirstPage,
  LastPage,
  ChevronLeft,
  ChevronRight,
  AddBox,
} from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import VendorDetails from './vendorDetails';
import UserDetails from '../Division/userDetails';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
};

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const ListVendorSelected = ({
  vendor,
  divisionidofuser,
  employeeNumber,
  refresh,
  setRefresh,
  divisions,
  accountType
}) => {
  const [divisionid] = useState(divisionidofuser);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const handleOnClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let vendorlist = [];
  let SAvendorlist = [];

  if (vendor) {
    vendorlist = vendor.map((vnd) => (
      {
        auto_no: vnd.AUTO_NO,
        vendor_id: vnd.VENDOR_ID || null,
        vendor_name: vnd.VENDOR_NAME || '',
        vendor_status: vnd.VENDOR_STATUS || '',
        division_id: vnd.DIVISION_ID || null,
        division_name: vnd.DIVISION_NAME || ''
      }
    )).filter((row) => row.division_id === divisionid) || [];
  }
  if (vendor) {
    SAvendorlist = vendor.map((vnd) => (
      {
        auto_no: vnd.AUTO_NO,
        vendor_id: vnd.VENDOR_ID || null,
        vendor_name: vnd.VENDOR_NAME || '',
        vendor_status: vnd.VENDOR_STATUS || '',
        division_id: vnd.DIVISION_ID || null,
        division_name: vnd.DIVISION_NAME || ''
      }
    ));
  }

  return (
    <>
      &nbsp;
      <Typography
        color="primary"
        style={{ fontWeight: 500 }}
      >
        LIST OF VENDOR
      </Typography>
      &nbsp;
      {accountType === 'Division Admin' ? (
        <MaterialTable
          icons={tableIcons}
          title=" "
          columns={[
            //   { title: 'ID', field: 'vendor_id' },
            { title: 'Vendor Name', field: 'vendor_name' },
            { title: 'Status', field: 'vendor_status' }
          ]}
          data={vendorlist}
          options={{
            search: true,
            grouping: true,
            rowStyle: () => ({
              fontFamily: 'helvetica',
            })
          }}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          onRowClick={((event, selectedRowData) => {
            handleOnClick();
            setSelectedRow(selectedRowData);
          })}
        />
      ) : (
        <MaterialTable
          icons={tableIcons}
          title=" "
          columns={[
            // { title: 'ID', field: 'vendor_id' },
            { title: 'Vendor Name', field: 'vendor_name' },
            // { title: 'Status', field: 'vendor_status' },
            { title: 'Division', field: 'division_name' }
          ]}
          data={SAvendorlist}
          options={{
            search: true,
            grouping: true,
            rowStyle: () => ({
              fontFamily: 'helvetica',
            })
          }}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          onRowClick={((event, selectedRowData) => {
            handleOnClick();
            setSelectedRow(selectedRowData);
          })}
        />
      )}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Vendor
        </DialogTitle>
        <DialogContent dividers>
          <VendorDetails
            vnd={selectedRow}
            close={handleClose}
            employeeNumber={employeeNumber}
            refresh={refresh}
            setRefresh={setRefresh}
            setOpen={setOpen}
            divisions={divisions}
            accountType={accountType}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

ListVendorSelected.propTypes = {
  vendor: PropTypes.array,
  divisionidofuser: PropTypes.string,
  employeeNumber: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  accountType: PropTypes.string,
  divisions: PropTypes.array
};

export default ListVendorSelected;

export function ListVendorAccount({
  vendorAccount,
  // divisionidofuser,
  employeeNumber,
  refresh,
  setRefresh,
  divisions,
  accountType
}) {
  // const [divisionid] = useState(divisionidofuser);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const handleOnClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let vendoraccountlist = [];

  if (vendorAccount) {
    vendoraccountlist = vendorAccount.map((vendor) => (
      {
        employeenumber: vendor.EMPLOYEE_NUMBER || null,
        name: vendor.EMPLOYEE_NAME || '',
        login_status: vendor.LOGIN_STATUS || '',
        account_type: vendor.ACCOUNT_TYPE || ''
      }
    ))/* .filter((row) => row.division_id === divisionid) || [] */;
  }

  return (
    <>
      &nbsp;
      <Typography
        color="primary"
        style={{ fontWeight: 500 }}
      >
        LIST OF VENDOR ACCOUNT
      </Typography>
      &nbsp;
      <MaterialTable
        icons={tableIcons}
        title=" "
        columns={[
          { title: 'Username', field: 'employeenumber' },
          { title: 'Vendor Name', field: 'name' },
          { title: 'Status', field: 'login_status' }
        ]}
        data={vendoraccountlist}
        options={{
          search: true,
          grouping: true,
          rowStyle: () => ({
            fontFamily: 'helvetica',
          })
        }}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        onRowClick={((event, selectedRowData) => {
          handleOnClick();
          setSelectedUser(selectedRowData);
        })}
      />
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Vendor/Customer Account
        </DialogTitle>
        <DialogContent dividers>
          <UserDetails
            selectedUser={selectedUser}
            close={handleClose}
            employeeNumberuser={employeeNumber}
            refresh={refresh}
            setRefresh={setRefresh}
            setOpenDialog={setOpen}
            divisions={divisions}
            accountType={accountType}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

ListVendorAccount.propTypes = {
  vendorAccount: PropTypes.array,
  // divisionidofuser: PropTypes.string,
  employeeNumber: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  accountType: PropTypes.string,
  divisions: PropTypes.array
};
