import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Tooltip,
  Button,
  Container,
  Box,
  Typography,
  Divider,
  CardHeader,
  Paper
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';

import Page from 'src/components/Page';
import EquipmentRequestTable from './EquipmentRequestTable';
import EquipmentRequestForm from './EquipmentRequestForm';
import {
  fetchEquipmentRequest
} from '../../../actions/equipment';
import Popup from '../../../components/Popup';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';
import useStyles from '../../../components/defaultPageStyle';

function Index() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [user] = useState(
    JSON.parse(localStorage.getItem('profile'))?.result
  );
  const [equipmentRequest, setEquipmentRequest] = useState([]);
  const [currentPage] = useState('EquipmentRequest');
  const [refresh, setRefresh] = useState(true);
  const [openForm, setOpenForm] = useState(false);
  const classes = useStyles();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  const fetchData = (skipLoadingLoop) => {
    dispatch(fetchEquipmentRequest(user?.EMPLOYEE_NUMBER, skipLoadingLoop))
      .then((res) => {
        if (res?.success === true) {
          if (skipLoadingLoop !== true) {
            enqueueSnackbar({
              message: res?.message,
              options: {
                key: new Date().getTime() + Math.random(),
                action: (key) => (
                  <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                ),
                success: res?.success,
                notifier: res?.notifier || null,
              },
            });
          }
          setEquipmentRequest(res?.requestList || []);
        } else {
          if (skipLoadingLoop !== true) {
            enqueueSnackbar({
              message: res?.message,
              options: {
                key: new Date().getTime() + Math.random(),
                action: (key) => (
                  <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                ),
                success: res?.success,
                notifier: res?.notifier || null,
              },
            });
          }

          if (res?.relogin === true) {
            history('/login');
          }
        }
      });
  };

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => fetchData(true), 300000);
    return () => clearInterval(interval);
  }, [refresh]);

  return (
    <Page
      className={classes.root}
      title="Equipment - Prism"
    >
      <Container maxWidth={false}>
        <Box
          display="flex"
          width="100%"
        >
          <Box
            fontFamily="Roboto"
            display="flex"
            justifyContent="flex-start"
            flexGrow={1}
          >
            <Typography variant="h1" component="h2" gutterBottom color="textPrimary">
              Equipment
            </Typography>
          </Box>
          {user?.JOB_ID !== 'SGM' && (
            <>
              <Box>
                <Tooltip title="Submit New Equipment Request" aria-label="add">
                  <Button
                    variant="contained"
                    color="primary"
                    tooltip="Add Type"
                    onClick={handleOpenForm}
                  >
                    ADD REQUEST
                  </Button>
                </Tooltip>
              </Box>
            </>
          )}
        </Box>
        {user?.JOB_ID === 'SGM' && (
          <>
            <Box
              mb={2}
            >
              <Alert
                severity="info"
              >
                To ensure an efficient process for equipment requests, we have
                decided to exclude the ability of all SMT to make equipment requests.
                However, SMT can still make an equipment request by using a request form
                (physical or softcopy approval). Please contact the division in charge to
                request for the form. Thank you for your understanding and cooperation in
                this matter.
              </Alert>
            </Box>
          </>
        )}
        <Paper>
          <Box>
            <CardHeader
              title="Equipment Requested"
              subheader="List of all existing equipment request."
            />
            <Divider />
            <Box>
              <EquipmentRequestTable
                user={user}
                equipmentRequest={equipmentRequest}
                currentPage={currentPage}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            </Box>
          </Box>
        </Paper>
      </Container>

      <Popup
        openPopup={openForm}
        setOpenPopup={setOpenForm}
        title="Request Equipment"
      >
        <EquipmentRequestForm
          user={user}
          refresh={refresh}
          setRefresh={setRefresh}
          setOpenPopup={setOpenForm}
        />
      </Popup>

    </Page>
  );
}

export default Index;
