import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import reducers from './reducers';

import * as serviceWorker from './serviceWorker';
import App from './App';
import './polyfills';

const store = configureStore({
  reducer: reducers
});

ReactDOM.render(
  <Provider store={store}>
    <SnackbarProvider>
      <HashRouter>
        <App />
      </HashRouter>
    </SnackbarProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
