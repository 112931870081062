import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import RegisterView from 'src/views/auth/RegisterView';
import SettingsView from 'src/views/settings/SettingsView';
import ServiceView from 'src/views/services/ServiceView';
import ApprovalView from './views/approval/ApprovalView';
import Faqs from './views/faqs/faqs';
import Administrator from './views/Administrator/index';
import AcknowledgmentView from './views/auth/AcknowledgementView';
import AccountActivationView from './views/auth/AccountActivationView';
import ServiceMonitorView from './views/reports/ServiceReportView';
import ForgotPasswordView from './views/auth/ForgotPasswordView';
import SelfResetPassword from './views/auth/SelfResetPassword';
import NoAccessView from './views/errors/NoAccessView';
import HomepageView from './views/homepage/HomepageView';
import Chatbot from './views/homepage/HomepageView/Chatbot';
import TestPage from './views/TestPage';
import CreateFaceIdForm from './views/settings/SettingsView/CreateFaceIdForm';
import TokenLogin from './views/auth/TokenLogin';
import AuthValidation from './views/auth/AuthValidationView';
import EquipmentView from './views/equipment/EquipmentView';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'equipment', element: <EquipmentView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: 'faqs', element: <Faqs /> },
      { path: 'service', element: <ServiceView /> },
      { path: 'service/open_add_form/:openAdd', element: <ServiceView /> },
      { path: 'approval', element: <ApprovalView /> },
      { path: 'administrator', element: <Administrator /> },
      { path: 'report', element: <ServiceMonitorView /> },
      { path: '', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: 'noaccess', element: <NoAccessView /> },
      { path: 'ack', element: <AcknowledgmentView /> },
      { path: '/activate/:token', element: <AccountActivationView /> },
      { path: '/resetpassword/:token', element: <SelfResetPassword /> },
      { path: 'forgotpassword', element: <ForgotPasswordView /> },
      { path: 'test', element: <TestPage /> },
      { path: 'virtual-prism-assistance', element: <Chatbot /> },
      { path: 'validate_auth', element: <AuthValidation /> },
      { path: '', element: <Navigate to="/homepage" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'homepage',
    element: <MainLayout />, // Wrap in a layout if needed
    children: [
      { path: 'settings/faceid_setup/:facialId/:timestamp/:employeeNumber/:prismToken', element: <CreateFaceIdForm /> },
      { path: 'tokenLogin/:iv/:token/:employeeNumber', element: <TokenLogin /> },
      { path: '', element: <HomepageView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
