import React, { useState, useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';
import PropTypes from 'prop-types';

export default function RichTextEditorJodit({
  placeholder,
  data,
  setVar
}) {
  const editor = useRef(null);
  const [content, setContent] = useState(data || '');
  const tempVar = setVar !== null ? setVar : (a) => { console.log(a); };

  const config = useMemo(() => (
    {
      readonly: false, // all options from https://xdsoft.net/jodit/doc/,
      placeholder: placeholder || 'Start typings...',
      theme: 'default',
      // spellcheck: true,
      enter: 'BR',
      toolbarAdaptive: true,
      uploader: {
        insertImageAsBase64URI: true,
      },
    }
  ), [placeholder]);

  const handleOnchange = (value) => {
    let tempVal = value;
    if (tempVal.includes('<td style="')) {
      // to add table border in data
      tempVal = tempVal.replaceAll('<td style="', '<td style="border:1px solid black; ');
    }
    tempVar(tempVal);
  };

  return (
    <JoditEditor
      ref={editor}
      value={content}
      config={config}
      // eslint-disable-next-line jsx-a11y/tabindex-no-positive
      // tabIndex={1} // tabIndex of textarea
      // preferred to use only this option to update the content for performance reasons
      onBlur={(newContent) => setContent(newContent)}
      onChange={(newContent) => {
        handleOnchange(newContent.toString());
      }}
    />
  );
}

RichTextEditorJodit.propTypes = {
  placeholder: PropTypes.any,
  data: PropTypes.any,
  setVar: PropTypes.any
};
