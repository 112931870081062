import React, { useEffect, useState } from 'react';
import decode from 'jwt-decode';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { activate } from '../../actions/auth';

function AccountActivationView() {
  const dispatch = useDispatch();
  const { token } = useParams();
  const [activated, setActivated] = useState(false);
  const [message, setMessage] = useState('');

  const checkToken = (param) => {
    const decodedToken = decode(param);
    const email = decodedToken?.email;
    const employeeNumber = decodedToken?.id;

    if (decodedToken.exp * 1000 < new Date().getTime()) {
      setMessage('Token Expired. Please Contact IT Division for support.');
    } else {
      dispatch(activate({ email, employee_number: employeeNumber }))
        .then((res) => {
          if (res?.success === true) {
            setMessage(res?.message || 'Activation success. You can now login');
            setActivated(true);
          } else {
            setMessage(res?.message || 'Activation fail. Please Contact IT Division for support.');
          }
        })
        .catch((err) => {
          setMessage(err);
        });
    }
  };

  useEffect(() => {
    checkToken(token);
  }, []);

  return (
    <>
      {activated === true ? (
        <>
          {message}
        </>
      ) : (
        <>
          {message}
        </>
      )}
    </>
  );
}

export default AccountActivationView;
