import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';

export default function RichTextViewerJodit({
  data
}) {
  return (
    <>
      <div>
        {ReactHtmlParser(data)}
      </div>
    </>
  );
}

RichTextViewerJodit.propTypes = {
  data: PropTypes.string,
};
