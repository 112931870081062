import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles,
  MenuItem,
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import { addEquipmentType } from '../../../actions/equipment';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const EquipmentTypeForm = ({
  employeeNumber,
  refresh,
  setRefresh,
  divisions,
  accountType,
  openPopup,
  setOpenPopup,
  employeeDivision
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const [clickDiv, setClickDiv] = useState('');
  const [clickDivName, setClickDivname] = useState('');
  const [tempTypeStatus, setTempTypeStatus] = useState('');
  const [tempTypeName, setTempTypeName] = useState('');
  const [tempInitialValue, setTempInitialValue] = useState({
    createBy: employeeNumber,
    divisionId: accountType === 'Super Admin' ? '' : employeeDivision,
    divisionName: '',
    typeStatus: '',
    typeName: '',
  });

  const handleDivClick = (div) => {
    setClickDiv(div?.DIVISION_ID);
    setClickDivname(div?.DIVISION);
  };

  useEffect(() => {
    setTempInitialValue({
      ...tempInitialValue,
      divisionId: accountType === 'Super Admin' ? clickDiv : employeeDivision,
      divisionName: clickDivName,
      typeStatus: tempTypeStatus,
      typeName: tempTypeName,
    });
  }, [
    clickDiv,
    clickDivName
  ]);
  return (
    <Page
      className={classes.root}
      title="Add Equipment Type"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        {accountType === 'Super Admin' ? (
          // SUPER ADMIN VIEW
          <Container maxWidth="sm">
            <Formik
              enableReinitialize
              initialValues={tempInitialValue}
              validationSchema={
                Yup.object().shape({
                  typeName: Yup.string().max(149).ensure().required('Please input type name'),
                  typeStatus: Yup.string().ensure().required('Please select status'),
                  divisionId: Yup.string().ensure().required('Please select divisions'),
                })
              }
              onSubmit={
                (values, { setSubmitting }) => {
                  dispatch(addEquipmentType(values))
                    .then((res) => {
                      if (res?.success === true) {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setRefresh(!refresh);
                        setOpenPopup(!openPopup);
                      } else {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        // setRefresh(!refresh);
                        setSubmitting(false);
                      }
                    })
                    .catch();
                }
              }
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Typography
                    color="primary"
                    style={{ fontWeight: 500 }}
                  >
                    Add New Type
                  </Typography>
                  <TextField
                    error={Boolean(touched.divisionId && errors.divisionId)}
                    fullWidth
                    helperText={touched.divisionId && errors.divisionId}
                    label="Select Division In Charge"
                    margin="normal"
                    name="divisionId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    value={values.divisionId}
                    variant="outlined"
                  >
                    {divisions ? (
                      divisions.map((div) => (
                        <MenuItem
                          value={div.DIVISION_ID}
                          key={div.DIVISION_ID}
                          onClick={() => handleDivClick(div)}
                          style={{ whiteSpace: 'unset' }}
                        >
                          {div.DIVISION}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    )}
                  </TextField>
                  <TextField
                    error={Boolean(touched.typeName && errors.typeName)}
                    fullWidth
                    helperText={touched.typeName && errors.typeName}
                    label="Type Name"
                    margin="normal"
                    name="typeName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onChangeCapture={(e) => {
                      setTempTypeName(e.target.value);
                    }}
                    value={values.typeName}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.typeStatus && errors.typeStatus)}
                    fullWidth
                    helperText={touched.typeStatus && errors.typeStatus}
                    label="Select status"
                    margin="normal"
                    name="typeStatus"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onClick={(e) => {
                      setTempTypeStatus(e.target.value);
                    }}
                    select
                    value={values.typeStatus}
                    variant="outlined"
                  >
                    <MenuItem value="active">
                      <em>Active</em>
                    </MenuItem>
                    <MenuItem value="inactive">
                      <em>Inactive</em>
                    </MenuItem>
                  </TextField>
                  <br />
                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      ADD
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        ) : (
          // DIVISION ADMIN VIEW
          <Container maxWidth="sm">
            <Formik
              enableReinitialize
              initialValues={tempInitialValue}
              validationSchema={
                Yup.object().shape({
                  typeName: Yup.string().max(149).ensure().required('Please input type name'),
                  typeStatus: Yup.string().ensure().required('Please select status'),
                })
              }
              onSubmit={
                (values, { setSubmitting }) => {
                  dispatch(addEquipmentType(values))
                    .then((res) => {
                      if (res?.success === true) {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setRefresh(!refresh);
                        setOpenPopup(!openPopup);
                      } else {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        // setRefresh(!refresh);
                        setSubmitting(false);
                      }
                    })
                    .catch();
                }
              }
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Typography
                    color="primary"
                    style={{ fontWeight: 500 }}
                  >
                    Add New Type
                  </Typography>
                  <TextField
                    error={Boolean(touched.typeName && errors.typeName)}
                    fullWidth
                    helperText={touched.typeName && errors.typeName}
                    label="Type Name"
                    margin="normal"
                    name="typeName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onChangeCapture={(e) => {
                      setTempTypeName(e.target.value);
                    }}
                    value={values.typeName}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.typeStatus && errors.typeStatus)}
                    fullWidth
                    helperText={touched.typeStatus && errors.typeStatus}
                    label="Select status"
                    margin="normal"
                    name="typeStatus"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onClick={(e) => {
                      setTempTypeStatus(e.target.value);
                    }}
                    select
                    value={values.typeStatus}
                    variant="outlined"
                  >
                    <MenuItem value="active">
                      <em>Active</em>
                    </MenuItem>
                    <MenuItem value="inactive">
                      <em>Inactive</em>
                    </MenuItem>
                  </TextField>
                  <br />
                  <Box my={2}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      ADD
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        )}
      </Box>
    </Page>
  );
};

EquipmentTypeForm.propTypes = {
  employeeNumber: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.bool,
  divisions: PropTypes.array,
  openPopup: PropTypes.bool,
  setOpenPopup: PropTypes.func,
  accountType: PropTypes.string,
  employeeDivision: PropTypes.string,
};

export default EquipmentTypeForm;
