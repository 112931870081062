/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import QRCode from 'qrcode';
import {
  Box,
  TextField,
  Button,
  Container,
  Typography,
  Divider
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';

import Popup from 'src/components/Popup';
import { totpVerify } from '../../actions/auth';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../actions/notifier';

const TOTPVerificationView = ({
  data
}) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);
  const [qrcodeUrl, setqrCodeUrl] = useState('');
  const [qrCodeAndroidUrl, setQrCodeAndroidUrl] = useState('');
  const [qrCodeIosUrl, setQrCodeIosUrl] = useState('');
  const globalUserId = useSelector((state) => state?.auth?.userId);
  const [initialValues, setInitialValues] = useState({
    totp_code: '',
    employee_number: globalUserId,
    mfa_id: ''
  });
  const [tempInitialValues, setTempInitialValues] = useState({});
  const [errorCount, setErrorCount] = useState(0);
  const [retryLimit] = useState(3);
  const [openDownload, setOpenDownload] = useState(false);
  const [downloadType, setDownloadType] = useState(false);
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  const popupNotification = (res) => {
    enqueueSnackbar({
      message: res?.message,
      options: {
        key: new Date().getTime() + Math.random(),
        action: (key) => (
          <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
        ),
        success: res?.success,
        notifier: res?.notifier || null,
      },
    });
  };

  const handleClickDownload = (val) => {
    setOpenDownload(true);
    setDownloadType(val);
  };

  const handleError = (val) => {
    setMessage(val);
    setError(true);
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      popupNotification({
        success: true,
        notifier: 'info',
        message: 'Secret Key copied to your clipboard.'
      });
    } catch (err1) {
      console.error('Failed to copy text 1st: ', err1);
      const textarea = document.createElement('textarea');
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand('copy');
        popupNotification({
          success: true,
          notifier: 'info',
          message: 'Secret Key copied to your clipboard.'
        });
      } catch (err2) {
        console.error('Failed to copy text 2nd: ', err2);
      } finally {
        document.body.removeChild(textarea);
      }
    }
  };

  useEffect(() => {
    QRCode.toDataURL(data?.otpauth_url).then((input) => setqrCodeUrl(input));
    QRCode.toDataURL(process.env.REACT_APP_GOOGLE_AUTHENTICATOR_ANDROID_LINK).then((input) => setQrCodeAndroidUrl(input));
    QRCode.toDataURL(process.env.REACT_APP_GOOGLE_AUTHENTICATOR_IOS_LINK).then((input) => setQrCodeIosUrl(input));

    setTempInitialValues({
      ...tempInitialValues,
      totp_code: '',
      employee_number: globalUserId,
      mfa_id: data?.mfa_id
    });
    return () => {
      setError(false);
      setMessage(null);
    };
  }, [data]);

  // This usestate will run when tempInitialValue update
  useEffect(() => {
    setInitialValues({
      ...initialValues,
      mfa_id: tempInitialValues?.mfa_id,
      totp_code: tempInitialValues?.totp_code
    });
  }, [tempInitialValues]);

  return (
    <>
      {error && (
        <>
          <Alert severity="error">{message}</Alert>
        </>
      )}
      <Box
        display="flex"
        mt="2%"
      >
        <Typography
          variant="h1"
          gutterBottom
          align="center"
        >
          Verification of TOTP Authentication
        </Typography>
        <Divider />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        mt="2%"
      >
        <Typography
          align="left"
          component="div"
        >
          <Container maxWidth="md">
            <Box m={2}>
              <Typography
                variant="h3"
                gutterBottom
              >
                Configuring Google Authenticator
              </Typography>
              <Divider />
              <Typography
                variant="body1"
              >
                <Box>
                  1. Install Google Authenticator (IOS - Android).
                </Box>
                <Box ml={2} m={1} mb={1}>
                  <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    fullWidth
                    onClick={() => handleClickDownload('android')}
                  >
                    Click Here for Android.
                  </Button>
                  <br />
                  <br />
                  <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    fullWidth
                    onClick={() => handleClickDownload('ios')}
                  >
                    Click Here for iOS.
                  </Button>
                </Box>
                <Box>
                  2. In the authenticator app, select &quot;+&quot; icon.
                </Box>
                <Box>
                  3. Select &quot;Scan a barcode (or QR code)&quot; and use the phone&apos;s camera
                </Box>
              </Typography>
            </Box>

            <Box m={2}>
              <Typography
                variant="h3"
                gutterBottom
              >
                Scan QR Code
              </Typography>
              <Divider />
              <Box mb={1}>
                <img
                  className="block w-64 h-64 object-contain"
                  src={qrcodeUrl}
                  alt="qrcode url"
                />
              </Box>
            </Box>
            <Box m={2}>
              <Typography
                variant="h3"
                gutterBottom
              >
                Or Enter Code Into Your App
              </Typography>
              <Divider />
              <Typography
                variant="body1"
              >
                {`SecretKey: ${data?.base32} (Base32 encoded)`}
              </Typography>
              <Button
                color="primary"
                variant="contained"
                size="small"
                fullWidth
                onClick={() => {
                  copyToClipboard(data?.base32);
                }}
              >
                Copy Secret Key
              </Button>
            </Box>
          </Container>
        </Typography>
      </Box>
      <Popup
        openPopup={openDownload}
        setOpenPopup={setOpenDownload}
        title=""
      >
        <Box mb={1}>
          <center>
            <img
              className="block w-64 h-64 object-contain"
              src={downloadType === 'android' ? qrCodeAndroidUrl : downloadType === 'ios' ? qrCodeIosUrl : ''}
              alt="qrcode url"
            />
          </center>
          <Button
            href={downloadType === 'android' ? `${process.env.REACT_APP_GOOGLE_AUTHENTICATOR_ANDROID_LINK}` : downloadType === 'ios' ? `${process.env.REACT_APP_GOOGLE_AUTHENTICATOR_IOS_LINK}` : ''}
            target="_blank"
            rel="noopener noreferrer"
            color="primary"
            size="small"
            variant="contained"
            fullWidth
          >
            Or Click Here to Download.
          </Button>
        </Box>
      </Popup>
      <Container maxWidth="md">
        <Box
          m={2}
        >
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              totp_code: Yup.string().min(6, 'Code must be 6 digits.').max(6, 'Code must be 6 digits.').required('Authentication code is required.'),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              try {
                // Submit user credential to backend
                dispatch(totpVerify(values, history))
                  .then((res) => {
                    if (res?.success === false && errorCount <= retryLimit) {
                      popupNotification(res);
                      handleError(res?.message);
                      setSubmitting(false);
                      setErrorCount(errorCount + 1);
                    } else if (res?.success === false && errorCount > retryLimit) {
                      popupNotification({
                        message: 'Code activation and verification exceeds limit. Please relogin.',
                        notifier: res?.notifier,
                        success: res?.success
                      });
                      setSubmitting(false);
                      history('/login');
                    } else if (res?.success === true) {
                      popupNotification({
                        ...res,
                        message: `${res?.message} Please relogin.`
                      });
                      history('/login');
                    }
                  })
                  .catch(() => {
                    handleError('Server Error');
                    setSubmitting(false);
                  });
              } catch (err) {
                handleError(err);
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              // handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <>
                <Box>
                  <Typography
                    variant="h3"
                    gutterBottom
                  >
                    Verify Code
                  </Typography>
                  <Divider />
                  <Typography
                    variant="body1"
                  >
                    For changing the setting, please verify the authentication code:
                  </Typography>
                </Box>
                <form onSubmit={handleSubmit}>
                  <TextField
                    error={Boolean(touched.totp_code && errors.totp_code)}
                    fullWidth
                    helperText={touched.totp_code && errors.totp_code}
                    label="Authentication Code"
                    margin="normal"
                    name="totp_code"
                    onBlur={handleBlur}
                    onChange={(e) => setTempInitialValues({ ...tempInitialValues, totp_code: e.target.value })}
                    type="Text"
                    inputProps={{
                      maxLength: 6,
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    value={values.code}
                    variant="outlined"
                  />
                  <Box
                    my={2}
                  >
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Verify & Activate
                    </Button>
                  </Box>
                </form>
              </>
            )}
          </Formik>
        </Box>
      </Container>
    </>
  );
};

TOTPVerificationView.propTypes = {
  data: PropTypes.object
};

export default TOTPVerificationView;
