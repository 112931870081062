import React from 'react';
import {
  Box,
  Paper,
  makeStyles,
  TableBody,
  TableCell,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';

const UseStyles = makeStyles(() => ({
  prismFont: {
    display: 'inline-block',
    objectFit: 'cover',
    border: '10px',
    borderRadius: '4px 4px 0 0',
  },
  table: {
    minWidth: 'auto'
  },
}));

function ContactInformation() {
  const classes = UseStyles();

  function createData(department, name, contact, contactType) {
    return {
      department, name, contact, contactType
    };
  }

  const rows = [
    createData('Civil Management', 'Mariatul Qibtiyah Ahmad Khalid', 'mariatul.qibtiyah.ak@ptp.com.my', 'mail'),
    createData('Utilities & Mechanical', 'Muhammad Afiq Zulkifli', 'afiq.zulkifli@ptp.com.my', 'mail'),
    createData('Electrical Management', 'Shift Chargeman', '+60197268010', 'phone'),
    createData('Information Technology', 'IT Support', '075042222 Ext(2148 or 2149)', 'phone')
  ];

  return (
    <>
      <Box
        m={1}
        ml={3}
        mr={3}
        maxWidth="100%"
        width="100%"
      >
        {/* Contact Information */}
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
        <Typography
          gutterBottom
        >
          <Box
            fontWeight="fontWeightBold"
          >
            For urgent assistance outside office hours, please refer contact information below:
          </Box>
          <Box
            justifyContent="center"
            textAlign="center"
            display="flex"
            m={1}
            ml={3}
            mr={3}
          >
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Department</TableCell>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Contact Information</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.department}
                      </TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell
                        align="left"

                      >
                        <a
                          onClick={() => {
                            window.location = row.contactType === 'mail' ? `mailto:${row.contact}` : `tel:${row.contact}`;
                          }}
                        >
                          {row.contact}
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Typography>
      </Box>
    </>
  );
}

export default ContactInformation;
