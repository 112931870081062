import React, { useEffect, useState, forwardRef } from 'react';
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import {
  Typography,
  Paper,
  Dialog,
  IconButton,
} from '@material-ui/core';
import {
  Search,
  ArrowDownward,
  Clear,
  FirstPage,
  LastPage,
  ChevronLeft,
  ChevronRight,
  AddBox,
} from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

import AuthenticationMethodForm from './AuthenticationMethodForm';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
};

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function ListOfAuthenticationMethod({
  refresh,
  data,
  setRefresh,
}) {
  const [methodList, setMethodList] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [verifiedAuthId, setVerifiedId] = useState([]);

  const handleOnClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const inputMethodList = (list) => {
    const tempList = list?.map((row) => (
      {
        id: row.id || '',
        auth_name: row.AUTH_NAME || '',
        verification_status: verifiedAuthId.filter((item) => item?.id === row?.id)?.length !== 0 ? 'Verified' : 'Unverified',
        auth_status: row.AUTH_STATUS || '',
        auth_status_name: row.AUTH_STATUS === 'true' ? 'Active' : 'Inactive',
        auth_description: row.AUTH_DESCRIPTION || '',
        create_datetime: row.CREATE_DATETIME || '',
        create_by: row.CREATE_BY || '',
        update_datetime: row.UPDATE_DATETIME || '',
        update_by: row.UPDATE_BY || ''
      }
    ))
      || [];
    setMethodList(tempList);
  };

  useEffect(() => {
    setVerifiedId(() => {
      const envAuthId = process.env.REACT_APP_AUTHENTICATION_ID;
      if (typeof envAuthId !== 'undefined' && typeof envAuthId !== 'undefined') {
        const tempArray = JSON.parse(envAuthId);
        return tempArray;
      }
      return [];
    });
    inputMethodList(data?.method);
  }, [refresh, data]);

  return (
    <>
      &nbsp;
      <Typography
        color="primary"
        style={{ fontWeight: 500 }}
      >
        Authentication Method
      </Typography>
      &nbsp;
      <MaterialTable
        icons={tableIcons}
        title=""
        columns={[
          { title: 'ID', field: 'id' },
          { title: 'Name', field: 'auth_name' },
          { title: 'Status', field: 'auth_status_name' },
          { title: 'Verification Status', field: 'verification_status' },
          { title: 'Description', field: 'auth_description' },
          { title: 'Create Date', field: 'create_datetime' },
          { title: 'Create By', field: 'create_by' },
          { title: 'Update Date', field: 'update_datetime' },
          { title: 'Update By', field: 'update_by' }
        ]}
        data={methodList}
        options={{
          search: true,
          grouping: true,
          rowStyle: () => ({
            fontFamily: 'helvetica',
          })
        }}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        onRowClick={((event, selectedRowData) => {
          handleOnClick();
          setSelectedRow(selectedRowData);
        })}
      />
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Authentication Method
        </DialogTitle>
        <DialogContent dividers>
          <AuthenticationMethodForm
            openPopup={open}
            setOpenPopup={setOpen}
            refresh={refresh}
            setRefresh={setRefresh}
            data={selectedRow}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

ListOfAuthenticationMethod.propTypes = {
  data: PropTypes.object,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func
};

export default ListOfAuthenticationMethod;
