import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles,
  MenuItem,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { red } from '@material-ui/core/colors';
import { Alert } from '@material-ui/lab';

import Popup from 'src/components/Popup';
import RichTextEditorJodit from 'src/components/RichTextEditorJodit';
import {
  deleteConsentAgreement,
  updateConsentAgreement,
  listOfEquipmentType,
  listOfAllEquipmentType
} from '../../../actions/equipment';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';
import ConsentAgreementViewer from './ConsentAgreementViewer';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ConsentAgreementDetails = ({
  data,
  employeeNumber,
  refresh,
  setRefresh,
  accountType,
  openPopup,
  setOpenPopup,
  employeeDivision
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const [equipmentTypeList, setEquipmentTypeList] = useState([]);
  const [openEditDetails, setOpenEditDetails] = useState(false);
  const [clickDelete, setClickDelete] = useState(false);
  const [tempAgreementDetails, setTempAgreementDetails] = useState(data.agreement_details);
  const [tempTypeId, setTempTypeId] = useState(data.equipment_type_id);
  const [tempAgreementStatus, setTempAgreementStatus] = useState(data.agreement_status);
  const [tempAgreementName, setTempAgreementName] = useState(data.agreement_name);
  const [openDetails, setOpenDetails] = useState(false);
  const [tempInitialValue, setTempInitialValue] = useState({
    updateBy: employeeNumber,
    typeId: tempTypeId,
    agreementStatus: tempAgreementStatus,
    agreementId: data.agreement_id,
    agreementName: tempAgreementName,
    agreementDetails: tempAgreementDetails,
  });

  const filterEquipmentTypeList = (list) => {
    const tempArrayList = [];
    list.map((row) => {
      if (row.TYPE_STATUS !== 'deleted') {
        tempArrayList.push({
          CREATE_BY: row.CREATE_BY,
          CREATE_DATE: row.CREATE_DATE,
          DIVISION_ID: row.DIVISION_ID,
          DIVISION_NAME: row.DIVISION_NAME,
          EFFECTIVE_FROM_DATE: row.EFFECTIVE_FROM_DATE,
          EFFECTIVE_TO_DATE: row.EFFECTIVE_TO_DATE,
          TYPE_ID: row.TYPE_ID,
          TYPE_NAME: row.TYPE_NAME,
          TYPE_STATUS: row.TYPE_STATUS,
          UPDATE_BY: row.UPDATE_BY,
          UPDATE_DATE: row.UPDATE_DATE
        });
      }
      return null;
    });
    return tempArrayList;
  };

  const handleOpenDetails = () => {
    setOpenDetails(!openDetails);
  };

  const handleTypeClick = (type) => {
    setTempTypeId(type?.TYPE_ID);
  };

  const handleClickDelete = () => {
    setClickDelete(true);
  };

  const handleDelCancel = () => {
    setClickDelete(!clickDelete);
  };

  const handleDelete = (agreementId) => {
    dispatch(deleteConsentAgreement(agreementId, { employeeNumber }))
      .then((res) => {
        if (res?.success === true) {
          enqueueSnackbar({
            message: res?.message,
            options: {
              key: new Date().getTime() + Math.random(),
              action: (key) => (
                <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
              ),
              success: res?.success,
              notifier: res?.notifier || null,
            },
          });
          setRefresh(!refresh);
          setClickDelete(false);
          setOpenPopup(!openPopup);
        } else {
          enqueueSnackbar({
            message: res?.message,
            options: {
              key: new Date().getTime() + Math.random(),
              action: (key) => (
                <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
              ),
              success: res?.success,
              notifier: res?.notifier || null,
            },
          });
        }
      })
      .catch();
  };

  const retrieveData = () => {
    if (accountType === 'Super Admin') {
      dispatch(listOfAllEquipmentType())
        .then((res) => {
          if (res?.success === true) {
            setEquipmentTypeList(filterEquipmentTypeList(res?.equipmentType) || []);
          }
        });
    } else if (accountType === 'Division Admin') {
      dispatch(listOfEquipmentType(employeeDivision))
        .then((res) => {
          if (res?.success === true) {
            setEquipmentTypeList(filterEquipmentTypeList(res?.equipmentType) || []);
          }
        });
    }
  };

  useEffect(() => {
    setTempInitialValue({
      ...tempInitialValue,
      agreementDetails: tempAgreementDetails,
      typeId: tempTypeId,
      agreementStatus: tempAgreementStatus,
      agreementName: tempAgreementName,
    });
  }, [
    tempAgreementDetails
  ]);

  useEffect(() => {
    retrieveData();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Add Equipment Type"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            enableReinitialize
            initialValues={tempInitialValue}
            validationSchema={
              Yup.object().shape({
                agreementName: Yup.string().max(149).ensure().required('Please input agreement name'),
                agreementStatus: Yup.string().ensure().required('Please select status'),
                agreementDetails: Yup.string().ensure().required('Please input agreement'),
                typeId: Yup.string().ensure().required('Please select type'),
              })
            }
            onSubmit={
              (values, { setSubmitting }) => {
                dispatch(updateConsentAgreement(values))
                  .then((res) => {
                    if (res?.success === true) {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setRefresh(!refresh);
                      setOpenPopup(!openPopup);
                    } else {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setSubmitting(false);
                    }
                  })
                  .catch();
              }
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                {clickDelete ? (
                  <>
                    <Box style={{ backgroundColor: red[50] }}>
                      <Typography style={{ marginLeft: 10, paddingTop: 10 }}>
                        Permanently remove below consent agreement?
                      </Typography>
                      <br />
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        Agreement ID
                      </Typography>
                      <Typography variant="h5" display="inline" style={{ marginLeft: 32 }}>
                        :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {data.agreement_id}
                      </Typography>
                      <br />
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        Agreement Name :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {data.agreement_name !== '' ? data.agreement_name : '-'}
                      </Typography>
                      <br />
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        Equipment Type :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {data.equipment_type_id !== '' ? `(${data.equipment_type_id}) ${data.equipment_type_name}` : '-'}
                      </Typography>
                      <br />
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        Create by :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {data.create_by !== '' ? data.create_by : '-'}
                      </Typography>
                      <br />
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        Create date :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {data.create_date !== '' ? data.create_date : '-'}
                      </Typography>
                      <br />
                      <br />
                    </Box>
                    <br />
                    <br />
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        variant="contained"
                        onClick={() => handleDelete(data?.agreement_id)}
                      >
                        Yes
                      </Button>
                      &nbsp;
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        variant="outlined"
                        onClick={handleDelCancel}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    {/* hide delete button if status deleted */}
                    {data?.agreement_status !== 'deleted' && (
                      <>
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          onClick={() => handleClickDelete()}
                        >
                          <Tooltip title="Delete Agreement">
                            <IconButton aria-label="delete">
                              <DeleteForeverIcon style={{ color: 'red' }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </>
                    )}
                    <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                      Agreement ID
                    </Typography>
                    <Typography variant="h5" display="inline" style={{ marginLeft: 32 }}>
                      :
                    </Typography>
                    &nbsp;
                    <Typography display="inline" style={{ marginLeft: 10 }}>
                      {data.agreement_id}
                    </Typography>
                    <br />
                    <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                      Effective from Date :
                    </Typography>
                    &nbsp;
                    <Typography display="inline" style={{ marginLeft: 10 }}>
                      {data.effective_from_date !== '' ? data.effective_from_date : '-'}
                    </Typography>
                    <br />
                    <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                      Effective to Date :
                    </Typography>
                    &nbsp;
                    <Typography display="inline" style={{ marginLeft: 10 }}>
                      {data.effective_to_date !== '' ? data.effective_to_date : '-'}
                    </Typography>
                    <br />
                    <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                      Create by :
                    </Typography>
                    &nbsp;
                    <Typography display="inline" style={{ marginLeft: 10 }}>
                      {data.create_by !== '' ? data.create_by : '-'}
                    </Typography>
                    <br />
                    <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                      Create date :
                    </Typography>
                    &nbsp;
                    <Typography display="inline" style={{ marginLeft: 10 }}>
                      {data.create_date !== '' ? data.create_date : '-'}
                    </Typography>
                    <br />
                    <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                      Updated by :
                    </Typography>
                    &nbsp;
                    <Typography display="inline" style={{ marginLeft: 10 }}>
                      {data.update_by !== '' ? data.update_by : '-'}
                    </Typography>
                    <br />
                    <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                      Updated date :
                    </Typography>
                    &nbsp;
                    <Typography display="inline" style={{ marginLeft: 10 }}>
                      {data.update_date !== '' ? data.update_date : '-'}
                    </Typography>
                    {data?.agreement_status === 'deleted' ? (
                      <>
                        {/* show this section if the agreement is deleted */}
                        <br />
                        <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                          Consent Agreement  :
                        </Typography>
                        &nbsp;
                        <Typography display="inline" style={{ marginLeft: 10 }}>
                          {data.equipment_type_id !== '' ? `(${data.equipment_type_id}) ${data.equipment_type_name}` : '-'}
                        </Typography>
                        <br />
                        <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                          Agreement Name :
                        </Typography>
                        &nbsp;
                        <Typography display="inline" style={{ marginLeft: 10 }}>
                          {data.agreement_name !== '' ? data.agreement_name : '-'}
                        </Typography>
                        <br />
                        <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                          Status :
                        </Typography>
                        &nbsp;
                        <Typography display="inline" style={{ marginLeft: 10 }}>
                          {data.agreement_status !== '' ? data.agreement_status : '-'}
                        </Typography>
                        <br />
                        <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                          Agreement Details :
                        </Typography>
                        &nbsp;
                        <Typography
                          display="inline"
                          style={{ marginLeft: 10 }}
                        >
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                              handleOpenDetails();
                            }}
                          >
                            Click here to view
                          </Button>
                        </Typography>
                      </>
                    ) : (
                      <>
                        {/* show this section if the agreement is NOT deleted */}
                        <TextField
                          error={Boolean(touched.typeId && errors.typeId)}
                          fullWidth
                          helperText={touched.typeId && errors.typeId}
                          label="Equipment Type "
                          margin="normal"
                          name="typeId"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          select
                          value={values.typeId}
                          variant="outlined"
                        >
                          {equipmentTypeList?.length !== 0 ? (
                            equipmentTypeList.map((type) => (
                              <MenuItem
                                value={type.TYPE_ID}
                                key={type.TYPE_ID}
                                style={{ whiteSpace: 'unset' }}
                                onClick={() => {
                                  handleTypeClick(type);
                                }}
                              >
                                {`(${type.DIVISION_NAME}) [${type.TYPE_STATUS?.toUpperCase()}] ${type.TYPE_NAME}`}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                          )}
                        </TextField>
                        <TextField
                          error={Boolean(touched.agreementName && errors.agreementName)}
                          fullWidth
                          helperText={touched.agreementName && errors.agreementName}
                          label="Agreement Name"
                          margin="normal"
                          name="agreementName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.agreementName}
                          variant="outlined"
                          onChangeCapture={(e) => {
                            setTempAgreementName(e.target.value);
                          }}
                        />
                        <TextField
                          error={Boolean(touched.agreementStatus && errors.agreementStatus)}
                          fullWidth
                          helperText={touched.agreementStatus && errors.agreementStatus}
                          label="Agreement status"
                          margin="normal"
                          name="agreementStatus"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          select
                          value={values.agreementStatus}
                          variant="outlined"
                          onClick={(e) => {
                            setTempAgreementStatus(e.target.value);
                          }}

                        >
                          <MenuItem value="active">
                            <em>Active</em>
                          </MenuItem>
                          <MenuItem value="inactive">
                            <em>Inactive</em>
                          </MenuItem>
                        </TextField>

                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={() => {
                            setOpenEditDetails(!openEditDetails);
                          }}
                        >
                          Click here to edit agreement
                        </Button>
                        <Popup
                          openPopup={openEditDetails}
                          setOpenPopup={setOpenEditDetails}
                          fullScreen
                          setServiceType=""
                          title="Agreement"
                        >
                          <RichTextEditorJodit
                            data={tempAgreementDetails}
                            setVar={setTempAgreementDetails}
                          />
                        </Popup>
                        {(errors.agreementDetails) && (
                          <>
                            <Alert severity="error">{errors.agreementDetails || ''}</Alert>
                          </>
                        )}
                        <br />
                        <Box my={2}>
                          <Button
                            color="primary"
                            disabled={isSubmitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                          >
                            UPDATE
                          </Button>
                        </Box>
                      </>
                    )}
                  </>
                )}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
      {openDetails === true && (
        <>
          <Popup
            openPopup={openDetails}
            setOpenPopup={setOpenDetails}
            fullScreen
            setServiceType=""
            title="Agreement"
          >
            <ConsentAgreementViewer
              data={data?.agreement_details}
            />
          </Popup>
        </>
      )}
    </Page>
  );
};

ConsentAgreementDetails.propTypes = {
  employeeNumber: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.bool,
  openPopup: PropTypes.bool,
  setOpenPopup: PropTypes.func,
  accountType: PropTypes.string,
  employeeDivision: PropTypes.string,
  data: PropTypes.object,
};

export default ConsentAgreementDetails;
