/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkMethod,
  checkAccountMFA,
  totpGenerate
} from '../../actions/user';
import TOTPVerificationView from './TOTPVerificationView';
import TOTPValidationView from './TOTPValidationView';

const AuthValidation = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);
  const [userMfaId, setUserMfaId] = useState('');
  const [openVerifyPage, setOpenVerifyPage] = useState(false);
  const [generateData, setGenerateData] = useState({});
  const [verifiedAuthData, setVerifiedAuthData] = useState([]);
  const globalAuthStatus = useSelector((state) => state?.auth?.authStatus);
  const globalUserId = useSelector((state) => state?.auth?.userId);
  const globalUserData = useSelector((state) => state?.auth?.userData);

  const handleError = (val) => {
    setMessage(val);
    setError(true);
  };

  function findAuthId(name) {
    const object = verifiedAuthData.find((obj) => obj.name === name);
    return object ? object.id : null;
  }

  useEffect(() => {
    async function validate() {
      let mfaId = '';
      let verificationStatus = '';
      if (globalAuthStatus !== null && globalUserId !== null) {
        await dispatch(checkAccountMFA(globalUserId)).then((res) => {
          if (res?.success === true) {
            mfaId = res?.data[0]?.MFA_ID;
            setUserMfaId(res?.data[0]?.MFA_ID);
            verificationStatus = res?.data[0]?.VERIFICATION_STATUS;
          } else {
            handleError(res?.message);
          }
        });
        await dispatch(checkMethod(mfaId)).then((res) => {
          if (res?.success === true) {
            if (res?.data?.status === 'false') {
              dispatch({ type: 'AUTH', payload: globalUserData });
              history('/app/dashboard');
            }
          } else {
            handleError(res?.message);
          }
        });

        if (verificationStatus === 'false') {
          await dispatch(totpGenerate({
            employee_number: globalUserId,
            mfa_id: mfaId
          })).then((res) => {
            if (res?.success === true) {
              const tempData = res?.data[0];
              tempData.mfa_id = mfaId;
              setGenerateData(tempData);
            } else {
              handleError(res?.message);
            }
          }).then(() => {
            setOpenVerifyPage(true);
          });
        }
      } else {
        history('/login');
      }
    }
    setVerifiedAuthData(() => {
      const envAuthId = process.env.REACT_APP_AUTHENTICATION_ID;
      if (typeof envAuthId !== 'undefined' && typeof envAuthId !== 'undefined') {
        const tempArray = JSON.parse(envAuthId);
        return tempArray;
      }
      return [];
    });
    validate();
  }, []);

  return (
    <>
      {error && (
        <>
          <Alert severity="error">{message}</Alert>
        </>
      )}
      {(userMfaId === findAuthId('TOTP_ID')) ? (
        <>
          {(openVerifyPage) ? (
            <Box
              m={2}
            >
              <TOTPVerificationView
                data={generateData}
              />
            </Box>
          ) : (
            <>
              <Box
                m={2}
              >
                <TOTPValidationView />
              </Box>
            </>
          )}
        </>
      ) : (userMfaId === findAuthId('FACE_ID')) ? (
        <>
          {/* Include FACE ID flow here */}
        </>
      ) : (
        <>
          {/* Else, dont show anything */}
        </>
      )}
    </>
  );
};

export default AuthValidation;
