import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Formik } from 'formik';
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import {
  Box,
  Button,
  Container,
  TextField,
  makeStyles,
  Typography,
  Paper,
  Dialog,
  IconButton,
  TextareaAutosize,
  Divider,
  MenuItem
} from '@material-ui/core';
import {
  Search,
  ArrowDownward,
  Clear,
  FirstPage,
  LastPage,
  ChevronLeft,
  ChevronRight,
  AddBox,
} from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import { createFAQ } from '../../../actions/admin';
import FAQsDetails from './FAQsDetails';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  roots: {
    width: '100%',
    marginTop: theme.spacing(0),
    overflowX: 'auto',
    overflowY: 'auto',
    marginLeftt: theme.spacing(0)
  },
  margin: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  list: {
    width: '100%',
    padding: 0,
    backgroundColor: '#e8eaf6',
    color: '#212121'
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(2)
  },
  textArea: {
    width: '100%'
  },
  margintop: {
    margin: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  noBorder: {
    color: 'black'
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const FAQsForm = ({
  divisionidofuser,
  refresh,
  setRefresh,
  divisions,
  accountType,
  employeeNumber
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  return (
    <Page
      className={classes.root}
      title="FAQ"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        {accountType !== 'Super Admin' ? (
          <Container maxWidth="sm">
            <Formik
              initialValues={{
                question: '',
                answer: '',
                division_id: divisionidofuser,
                create_by: employeeNumber,
              }}
              validationSchema={
                Yup.object().shape({
                  question: Yup.string().max(4000).required('Question is required'),
                  answer: Yup.string().max(4000).required('Answer is required'),
                })
              }
              onSubmit={
                (values, { setSubmitting, resetForm }) => {
                  dispatch(createFAQ(values))
                    .then((res) => {
                      if (res?.success === true) {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setRefresh(!refresh);
                      } else {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setSubmitting(false);
                        setRefresh(!refresh);
                      }
                    })
                    .catch();
                  resetForm({
                    values: {
                      question: '',
                      answer: '',
                      division_id: divisionidofuser,
                    }
                  });
                }
              }
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                resetForm,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Typography
                    color="primary"
                    style={{ fontWeight: 500 }}
                  >
                    NEW FAQ
                  </Typography>
                  <TextField
                    error={Boolean(touched.question && errors.question)}
                    fullWidth
                    helperText={(touched.question && errors.question)}
                    label="Question"
                    margin="normal"
                    name="question"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.question}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.answer && errors.answer)}
                    fullWidth
                    helperText={(touched.answer && errors.answer)}
                    multiline
                    margin="normal"
                    label="Answer"
                    name="answer"
                    variant="outlined"
                    inputProps={{
                      inputcomponent: TextareaAutosize,
                      rows: 6
                    }}
                    value={values.answer}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <br />
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <div>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                      >
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="reset"
                        variant="outlined"
                        onClick={resetForm}
                      >
                        Reset
                      </Button>
                    </div>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        ) : (
          // SUPER ADMIN VIEW
          <Container maxWidth="sm">
            <Formik
              initialValues={{
                question: '',
                answer: '',
                division_id: '',
                create_by: employeeNumber,
              }}
              validationSchema={
                Yup.object().shape({
                  question: Yup.string().max(4000).required('Question is required'),
                  answer: Yup.string().max(4000).required('Answer is required'),
                  division_id: Yup.string().ensure().required('Division in charge required'),
                })
              }
              onSubmit={
                (values, { setSubmitting, resetForm }) => {
                  dispatch(createFAQ(values))
                    .then((res) => {
                      if (res?.success === true) {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setRefresh(!refresh);
                      } else {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setSubmitting(false);
                        setRefresh(!refresh);
                      }
                    })
                    .catch();
                  resetForm({
                    values: {
                      question: '',
                      answer: '',
                      division_id: '',
                    }
                  });
                }
              }
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                resetForm,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Typography
                    color="primary"
                    style={{ fontWeight: 500 }}
                  >
                    NEW FAQ
                  </Typography>
                  <TextField
                    error={Boolean(touched.division_id && errors.division_id)}
                    fullWidth
                    helperText={touched.division_id && errors.division_id}
                    label="Select Division In Charge"
                    margin="normal"
                    name="division_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    select
                    value={values.division_id}
                    variant="outlined"
                  >
                    {divisions ? (
                      divisions.map((div) => (
                        <MenuItem
                          value={div.DIVISION_ID}
                          key={div.DIVISION_ID}
                          style={{ whiteSpace: 'unset' }}
                        >
                          {div.DIVISION}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    )}
                  </TextField>
                  <TextField
                    error={Boolean(touched.question && errors.question)}
                    fullWidth
                    helperText={(touched.question && errors.question)}
                    label="Question"
                    margin="normal"
                    name="question"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.question}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.answer && errors.answer)}
                    fullWidth
                    helperText={(touched.answer && errors.answer)}
                    multiline
                    margin="normal"
                    label="Answer"
                    name="answer"
                    variant="outlined"
                    inputProps={{
                      inputcomponent: TextareaAutosize,
                      rows: 6
                    }}
                    value={values.answer}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <br />
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <div>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                      >
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="reset"
                        variant="outlined"
                        onClick={resetForm}
                      >
                        Reset
                      </Button>
                    </div>
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        )}
      </Box>
    </Page>
  );
};

FAQsForm.propTypes = {
  divisionidofuser: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  divisions: PropTypes.array,
  accountType: PropTypes.string,
  employeeNumber: PropTypes.string,
};

export default FAQsForm;

export function ListFAQs({
  faqs,
  divisionidofuser,
  refresh,
  setRefresh,
  accountType,
  employeeNumber,
  divisions
}) {
  const classes = useStyles();
  const [divisionid] = useState(divisionidofuser);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const handleOnClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let faqslist = [];
  let SAfaqslist = [];

  if (faqs) {
    faqslist = faqs.map((faqq) => (
      {
        faq_id: faqq.FAQ_ID || null,
        question: faqq.QUESTION || '',
        answer: faqq.ANSWER || '',
        division_id: faqq.DIVISION_ID || null
      }
    )).filter((row) => row.division_id === divisionid) || [];
  }
  if (faqs) {
    SAfaqslist = faqs.map((faqq) => (
      {
        faq_id: faqq.FAQ_ID || null,
        question: faqq.QUESTION || '',
        answer: faqq.ANSWER || '',
        division_id: faqq.DIVISION_ID || null
      }
    ));
  }
  return (
    <>
      &nbsp;
      <Typography
        color="primary"
        style={{ fontWeight: 500 }}
      >
        LIST OF FAQ
      </Typography>
      &nbsp;
      {accountType === 'Division Admin' ? (
        <MaterialTable
          icons={tableIcons}
          title=" "
          columns={[
            // { title: 'ID', field: 'faq_id' },
            { title: 'Question', field: 'question' }
          ]}
          data={faqslist}
          options={{
            search: true,
            grouping: true,
            rowStyle: () => ({
              fontFamily: 'helvetica',
            })
          }}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          detailPanel={(rowData) => {
            return (
              // <div
              //   style={{
              //     fontSize: 20,
              //     textAlign: 'center',
              //     color: 'white',
              //     backgroundColor: '#FDD835',
              //   }}
              // >
              //   {rowData.answer}
              // </div>
              <Box className={classes.list}>
                <Typography
                  variant="h5"
                  className={clsx(classes.margintop)}
                >
                  Answer
                </Typography>
                <Divider />
                <Box>
                  <TextField
                    fullWidth
                    margin="normal"
                    name="answer"
                    multiline
                    value={rowData.answer}
                    disabled
                    InputProps={{
                      disableUnderline: true,
                      classes: { disabled: classes.noBorder }
                    }}
                    className={clsx(classes.button)}
                  />
                </Box>
              </Box>
            );
          }}
          onRowClick={((event, selectedRowData) => {
            handleOnClick();
            setSelectedRow(selectedRowData);
          })}
        />
      ) : (
        // SUPER ADMIN VIEW
        <MaterialTable
          icons={tableIcons}
          title=" "
          columns={[
            // { title: 'ID', field: 'faq_id' },
            { title: 'Question', field: 'question' },
            { title: 'Division ID', field: 'division_id' }
          ]}
          data={SAfaqslist}
          options={{
            search: true,
            grouping: true,
            rowStyle: () => ({
              fontFamily: 'helvetica',
            })
          }}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          detailPanel={(rowData) => {
            return (
              <Box className={classes.list}>
                <Typography
                  variant="h5"
                  className={clsx(classes.margintop)}
                >
                  Answer
                </Typography>
                <Divider />
                <Box>
                  <TextField
                    fullWidth
                    margin="normal"
                    name="answer"
                    multiline
                    value={rowData.answer}
                    disabled
                    InputProps={{
                      disableUnderline: true,
                      classes: { disabled: classes.noBorder }
                    }}
                    className={clsx(classes.button)}
                  />
                </Box>
              </Box>
            );
          }}
          onRowClick={((event, selectedRowData) => {
            handleOnClick();
            setSelectedRow(selectedRowData);
          })}
        />
      )}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          FAQ
        </DialogTitle>
        <DialogContent dividers>
          <FAQsDetails
            faqselected={selectedRow}
            close={handleClose}
            refresh={refresh}
            setRefresh={setRefresh}
            setOpenDialog={setOpen}
            employeeNumber={employeeNumber}
            accountType={accountType}
            divisions={divisions}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

ListFAQs.propTypes = {
  faqs: PropTypes.array,
  divisionidofuser: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  accountType: PropTypes.string,
  employeeNumber: PropTypes.string,
  divisions: PropTypes.array,
};
