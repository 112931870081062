/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import React,
{ useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Paper,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    // maxWidth: 310,
    transition: 'transform 0.15s ease-in-out',
    '&:hover': { transform: 'scale3d(1.05, 1.05, 1)' },
  },
});

function PageContent() {
  const [screenWidth, setScreenWidth] = useState(0);
  const [cardHover, setCardHover] = useState(false);
  const [focus, setFocus] = useState(0);
  const classes = useStyles();

  // Configure on which scrollY to on/off topbar
  const detectWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  // Logic to activate hover card in page content
  const handleCardHover = (status, no) => {
    setCardHover(status);
    setFocus(no);
  };

  useEffect(() => {
    // window.addEventListener('scroll', toggleTopbar);
    detectWidth();
  }, [window.innerWidth]);
  return (
    <>
      {/* Card Area */}
      <Box
        m={1}
        ml={3}
        mr={3}
        top={screenWidth <= 1170 ? 0 : -100}
        zIndex="tooltip"
        position="relative"
        maxWidth="100%"
      >
        <Box>
          <Grid
            container
            spacing={2}
          >
            {/* first grid */}
            <Grid item xs={12} sm={12} md={6} zeroMinWidth>
              <Paper
                elevation={24}
              >
                <Card
                  className={classes.root}
                  onMouseEnter={() => { handleCardHover(true, 1); }}
                  onMouseLeave={() => { handleCardHover(false, 1); }}
                >
                  <CardActionArea
                    onClick={() => { window.location.href = '#/login'; }}
                  >
                    <div style={{ position: 'relative' }}>
                      {((cardHover) && (focus === 1)) && (
                        <>
                          <div
                            style={{
                              height: '100%',
                              width: '100%',
                              background: '#33475B',
                              opacity: '.7',
                              position: 'absolute',
                              textAlign: 'center'
                            }}
                          />
                          <div style={{
                            position: 'absolute',
                            color: 'white',
                            top: '50%',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            fontSize: '200%'
                          }}
                          >
                            Click Here to Login  👇
                          </div>
                        </>
                      )}
                      <CardMedia
                        component="img"
                        alt="QC using tablet"
                        height="300"
                        image="/static/images/qc_plan.jpg"
                        title="Click to login"
                      />
                    </div>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        Login
                      </Typography>
                      {/* <Typography variant="body2" color="textSecondary" component="p">
                        Log into your existing PRISM account to manage all your service request and ticket. For new user, you may register here too!
                      </Typography> */}
                      <Typography variant="body2" color="textSecondary" component="p">
                        Log into your account to manage your service request and ticket or you may register for a new account here
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      href="/#/login"
                      fullWidth
                    >
                      Login Here
                    </Button>
                  </CardActions>
                </Card>
              </Paper>
            </Grid>

            {/* second grid */}
            <Grid item xs={12} sm={12} md={6} zeroMinWidth>
              <Paper
                elevation={24}
              >
                <Card
                  className={classes.root}
                  onMouseEnter={() => { handleCardHover(true, 2); }}
                  onMouseLeave={() => { handleCardHover(false, 2); }}
                >
                  <CardActionArea
                    onClick={() => { window.location.href = '#/virtual-prism-assistance'; }}
                  >
                    <div style={{ position: 'relative' }}>
                      {((cardHover) && (focus === 2)) && (
                        <>
                          <div
                            style={{
                              height: '100%',
                              width: '100%',
                              background: '#33475B',
                              opacity: '.7',
                              position: 'absolute',
                            }}
                          />
                          <div style={{
                            position: 'absolute',
                            color: 'white',
                            top: '50%',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            fontSize: '200%'
                          }}
                          >
                            Click Here to Chat  👇
                          </div>
                        </>
                      )}
                      <CardMedia
                        component="img"
                        alt="Virtual Assistant"
                        height="300"
                        image="/static/images/chatbot.gif"
                        title="Virtual Assistant"
                      />
                    </div>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        Virtual PRISM BOT 🤖
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        Chat with our Ai powered Virtual Bot for a swift and fast solution for your IT issues
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="large"
                      color="primary"
                      href="/#/virtual-prism-assistance"
                      target="_blank"
                      fullWidth
                      variant="contained"
                    >
                      Chat Here
                    </Button>
                  </CardActions>
                </Card>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default PageContent;
