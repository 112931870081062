import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  makeStyles,
  Grid,
  Paper,
  Button
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Page from 'src/components/Page';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import FAQsForm, { ListFAQs } from './FAQsView';
import { listFAQ } from '../../../actions/admin';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(1)
  }
}));

const ManageFAQs = ({
  divisionidofuser,
  employeeNumber,
  accountType,
  divisions
}) => {
  const history = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);
  const stateFAQs = useSelector((state) => state?.faqs);
  const [faqs, setFAQs] = useState(stateFAQs);
  const location = useLocation();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  const promptNotification = (res) => {
    if (res?.success === false) {
      enqueueSnackbar({
        message: res?.message,
        options: {
          key: new Date().getTime() + Math.random(),
          action: (key) => (
            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
          ),
          success: res?.success,
          notifier: res?.notifier || null,
        },
      });
      if (res?.relogin === true) {
        history('/login');
      }
    }
  };

  const retrieve = () => {
    dispatch(listFAQ())
      .then((res) => {
        promptNotification(res);
        setFAQs(res);
      });
  };

  useEffect(() => {
    retrieve();
    if (!employeeNumber) {
      history('/login');
    }
  }, [location, refresh]);

  return (
    <Page
      className={classes.root}
      title="FAQs"
    >
      <Paper>
        <Container maxWidth={false} className={clsx(classes.root)}>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              lg={6}
              md={12}
              xl={6}
              xs={12}
            >
              <ListFAQs
                faqs={faqs}
                divisionidofuser={divisionidofuser}
                employeeNumber={employeeNumber}
                refresh={refresh}
                setRefresh={setRefresh}
                accountType={accountType}
                divisions={divisions}
              />
            </Grid>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Divider orientation="vertical" flexItem />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Grid
              item
              lg={5}
              md={12}
              xl={5}
              xs={12}
            >
              <FAQsForm
                divisionidofuser={divisionidofuser}
                employeeNumber={employeeNumber}
                refresh={refresh}
                setRefresh={setRefresh}
                accountType={accountType}
                divisions={divisions}
              />
            </Grid>
          </Grid>
          &nbsp;
        </Container>
      </Paper>
    </Page>
  );
};

ManageFAQs.propTypes = {
  divisionidofuser: PropTypes.string,
  employeeNumber: PropTypes.string,
  accountType: PropTypes.string,
  divisions: PropTypes.array
};

export default ManageFAQs;
