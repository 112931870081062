import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles,
  MenuItem,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { red } from '@material-ui/core/colors';

import {
  deleteEquipmentType,
  updateEquipmentType
} from '../../../actions/equipment';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const EquipmentTypeDetails = ({
  data,
  employeeNumber,
  refresh,
  setRefresh,
  divisions,
  accountType,
  openPopup,
  setOpenPopup,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const [clickDiv, setClickDiv] = useState('');
  const [clickDivName, setClickDivname] = useState('');
  const [clickDelete, setClickDelete] = useState(false);
  const [tempInitialValue, setTempInitialValue] = useState({
    updateBy: employeeNumber,
    divisionId: data?.division_id,
    divisionName: data?.division_name,
    typeStatus: data?.type_status,
    typeId: data?.type_id,
    typeName: data?.type_name,
  });

  const handleDivClick = (div) => {
    setClickDiv(div?.DIVISION_ID);
    setClickDivname(div?.DIVISION);
  };

  const handleClickDelete = () => {
    setClickDelete(true);
  };

  const handleDelCancel = () => {
    setClickDelete(!clickDelete);
  };

  const handleDelete = (typeId) => {
    dispatch(deleteEquipmentType(typeId, { employeeNumber }))
      .then((res) => {
        if (res?.success === true) {
          enqueueSnackbar({
            message: res?.message,
            options: {
              key: new Date().getTime() + Math.random(),
              action: (key) => (
                <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
              ),
              success: res?.success,
              notifier: res?.notifier || null,
            },
          });
          setRefresh(!refresh);
          setClickDelete(false);
          setOpenPopup(!openPopup);
        } else {
          enqueueSnackbar({
            message: res?.message,
            options: {
              key: new Date().getTime() + Math.random(),
              action: (key) => (
                <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
              ),
              success: res?.success,
              notifier: res?.notifier || null,
            },
          });
        }
      })
      .catch();
  };

  useEffect(() => {
    setTempInitialValue({
      ...tempInitialValue,
    });
  }, [
    clickDiv,
    clickDivName
  ]);
  return (
    <Page
      className={classes.root}
      title="Add Equipment Type"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        {accountType === 'Super Admin' ? (
          // SUPER ADMIN VIEW
          <Container maxWidth="sm">
            <Formik
              enableReinitialize
              initialValues={tempInitialValue}
              validationSchema={
                Yup.object().shape({
                  typeName: Yup.string().max(149).ensure().required('Please input type name'),
                  typeStatus: Yup.string().ensure().required('Please select status'),
                  divisionId: Yup.string().ensure().required('Please select divisions'),
                })
              }
              onSubmit={
                (values, { setSubmitting }) => {
                  dispatch(updateEquipmentType(values))
                    .then((res) => {
                      if (res?.success === true) {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setRefresh(!refresh);
                        setOpenPopup(!openPopup);
                      } else {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        // setRefresh(!refresh);
                        setSubmitting(false);
                      }
                    })
                    .catch();
                }
              }
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  {clickDelete ? (
                    <>
                      <Box style={{ backgroundColor: red[50] }}>
                        <Typography style={{ marginLeft: 10, paddingTop: 10 }}>
                          Permanently remove below equipment type?
                        </Typography>
                        <br />
                        <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                          Type ID
                        </Typography>
                        <Typography variant="h5" display="inline" style={{ marginLeft: 32 }}>
                          :
                        </Typography>
                        &nbsp;
                        <Typography display="inline" style={{ marginLeft: 10 }}>
                          {data.type_id}
                        </Typography>
                        <br />
                        <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                          Type Name :
                        </Typography>
                        &nbsp;
                        <Typography display="inline" style={{ marginLeft: 10 }}>
                          {data.type_name !== '' ? data.type_name : '-'}
                        </Typography>
                        <br />
                        <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                          Division in charge :
                        </Typography>
                        &nbsp;
                        <Typography display="inline" style={{ marginLeft: 10 }}>
                          {data.division_id !== '' ? `(${data.division_id}) ${data.division_name}` : '-'}
                        </Typography>
                        <br />
                        <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                          Create by :
                        </Typography>
                        &nbsp;
                        <Typography display="inline" style={{ marginLeft: 10 }}>
                          {data.create_by !== '' ? data.create_by : '-'}
                        </Typography>
                        <br />
                        <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                          Create date :
                        </Typography>
                        &nbsp;
                        <Typography display="inline" style={{ marginLeft: 10 }}>
                          {data.create_date !== '' ? data.create_date : '-'}
                        </Typography>
                        <br />
                        <br />
                      </Box>
                      <br />
                      <br />
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          variant="contained"
                          onClick={() => handleDelete(data?.type_id)}
                        >
                          Yes
                        </Button>
                        &nbsp;
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          variant="outlined"
                          onClick={handleDelCancel}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      {/* hide delete button if status deleted */}
                      {data?.type_status !== 'deleted' && (
                        <>
                          <Box
                            display="flex"
                            justifyContent="flex-end"
                            onClick={() => handleClickDelete()}
                          >
                            <Tooltip title="Delete Type">
                              <IconButton aria-label="delete">
                                <DeleteForeverIcon style={{ color: 'red' }} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </>
                      )}
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        Type ID
                      </Typography>
                      <Typography variant="h5" display="inline" style={{ marginLeft: 32 }}>
                        :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {data.type_id}
                      </Typography>
                      <br />
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        Effective from Date :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {data.effective_from_date !== '' ? data.effective_from_date : '-'}
                      </Typography>
                      <br />
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        Effective to Date :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {data.effective_to_date !== '' ? data.effective_to_date : '-'}
                      </Typography>
                      <br />
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        Create by :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {data.create_by !== '' ? data.create_by : '-'}
                      </Typography>
                      <br />
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        Create date :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {data.create_date !== '' ? data.create_date : '-'}
                      </Typography>
                      <br />
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        Updated by :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {data.update_by !== '' ? data.update_by : '-'}
                      </Typography>
                      <br />
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        Updated date :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {data.update_date !== '' ? data.update_date : '-'}
                      </Typography>
                      {data?.type_status === 'deleted' ? (
                        <>
                          {/* show this section if the type is deleted */}
                          <br />
                          <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                            Division in charge :
                          </Typography>
                          &nbsp;
                          <Typography display="inline" style={{ marginLeft: 10 }}>
                            {data.division_id !== '' ? `(${data.division_id}) ${data.division_name}` : '-'}
                          </Typography>
                          <br />
                          <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                            Type Name :
                          </Typography>
                          &nbsp;
                          <Typography display="inline" style={{ marginLeft: 10 }}>
                            {data.type_name !== '' ? data.type_name : '-'}
                          </Typography>
                          <br />
                          <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                            Status :
                          </Typography>
                          &nbsp;
                          <Typography display="inline" style={{ marginLeft: 10 }}>
                            {data.type_status !== '' ? data.type_status : '-'}
                          </Typography>
                        </>
                      ) : (
                        <>
                          {/* show this section if the type is NOT deleted */}
                          <TextField
                            error={Boolean(touched.divisionId && errors.divisionId)}
                            fullWidth
                            helperText={touched.divisionId && errors.divisionId}
                            label="Select Division In Charge"
                            margin="normal"
                            name="divisionId"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            select
                            value={values.divisionId}
                            variant="outlined"
                          >
                            {divisions ? (
                              divisions.map((div) => (
                                <MenuItem
                                  value={div.DIVISION_ID}
                                  key={div.DIVISION_ID}
                                  onClick={() => handleDivClick(div)}
                                  style={{ whiteSpace: 'unset' }}
                                >
                                  {div.DIVISION}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                            )}
                          </TextField>
                          <TextField
                            error={Boolean(touched.typeName && errors.typeName)}
                            fullWidth
                            helperText={touched.typeName && errors.typeName}
                            label="Type Name"
                            margin="normal"
                            name="typeName"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            onChangeCapture={() => {
                              // setTempTypeName(e.target.value);
                            }}
                            value={values.typeName}
                            variant="outlined"
                          />
                          <TextField
                            error={Boolean(touched.typeStatus && errors.typeStatus)}
                            fullWidth
                            helperText={touched.typeStatus && errors.typeStatus}
                            label="Select status"
                            margin="normal"
                            name="typeStatus"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            onClick={() => {
                              // setTempTypeStatus(e.target.value);
                            }}
                            select
                            value={values.typeStatus}
                            variant="outlined"
                          >
                            <MenuItem value="active">
                              <em>Active</em>
                            </MenuItem>
                            <MenuItem value="inactive">
                              <em>Inactive</em>
                            </MenuItem>
                          </TextField>
                          <br />
                          <Box my={2}>
                            <Button
                              color="primary"
                              disabled={isSubmitting}
                              fullWidth
                              size="large"
                              type="submit"
                              variant="contained"
                            >
                              UPDATE
                            </Button>
                          </Box>
                        </>
                      )}
                    </>
                  )}
                </form>
              )}
            </Formik>
          </Container>
        ) : (
          // DIVISION ADMIN VIEW
          <Container maxWidth="sm">
            <Formik
              enableReinitialize
              initialValues={tempInitialValue}
              validationSchema={
                Yup.object().shape({
                  typeName: Yup.string().max(149).ensure().required('Please input type name'),
                  typeStatus: Yup.string().ensure().required('Please select status'),
                  divisionId: Yup.string().ensure().required('Please select divisions'),
                })
              }
              onSubmit={
                (values, { setSubmitting }) => {
                  dispatch(updateEquipmentType(values))
                    .then((res) => {
                      if (res?.success === true) {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        setRefresh(!refresh);
                        setOpenPopup(!openPopup);
                      } else {
                        enqueueSnackbar({
                          message: res?.message,
                          options: {
                            key: new Date().getTime() + Math.random(),
                            action: (key) => (
                              <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                            ),
                            success: res?.success,
                            notifier: res?.notifier || null,
                          },
                        });
                        // setRefresh(!refresh);
                        setSubmitting(false);
                      }
                    })
                    .catch();
                }
              }
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  {clickDelete ? (
                    <>
                      <Box style={{ backgroundColor: red[50] }}>
                        <Typography style={{ marginLeft: 10, paddingTop: 10 }}>
                          Permanently remove below equipment type?
                        </Typography>
                        <br />
                        <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                          Type ID
                        </Typography>
                        <Typography variant="h5" display="inline" style={{ marginLeft: 32 }}>
                          :
                        </Typography>
                        &nbsp;
                        <Typography display="inline" style={{ marginLeft: 10 }}>
                          {data.type_id}
                        </Typography>
                        <br />
                        <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                          Type Name :
                        </Typography>
                        &nbsp;
                        <Typography display="inline" style={{ marginLeft: 10 }}>
                          {data.type_name !== '' ? data.type_name : '-'}
                        </Typography>
                        <br />
                        <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                          Division in charge :
                        </Typography>
                        &nbsp;
                        <Typography display="inline" style={{ marginLeft: 10 }}>
                          {data.division_id !== '' ? `(${data.division_id}) ${data.division_name}` : '-'}
                        </Typography>
                        <br />
                        <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                          Create by :
                        </Typography>
                        &nbsp;
                        <Typography display="inline" style={{ marginLeft: 10 }}>
                          {data.create_by !== '' ? data.create_by : '-'}
                        </Typography>
                        <br />
                        <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                          Create date :
                        </Typography>
                        &nbsp;
                        <Typography display="inline" style={{ marginLeft: 10 }}>
                          {data.create_date !== '' ? data.create_date : '-'}
                        </Typography>
                        <br />
                        <br />
                      </Box>
                      <br />
                      <br />
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          variant="contained"
                          onClick={() => handleDelete(data?.type_id)}
                        >
                          Yes
                        </Button>
                        &nbsp;
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          variant="outlined"
                          onClick={handleDelCancel}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      {/* hide delete button if status deleted */}
                      {data?.type_status !== 'deleted' && (
                        <>
                          <Box
                            display="flex"
                            justifyContent="flex-end"
                            onClick={() => handleClickDelete()}
                          >
                            <Tooltip title="Delete Type">
                              <IconButton aria-label="delete">
                                <DeleteForeverIcon style={{ color: 'red' }} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </>
                      )}
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        Type ID
                      </Typography>
                      <Typography variant="h5" display="inline" style={{ marginLeft: 32 }}>
                        :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {data.type_id}
                      </Typography>
                      <br />
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        Effective from Date :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {data.effective_from_date !== '' ? data.effective_from_date : '-'}
                      </Typography>
                      <br />
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        Effective to Date :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {data.effective_to_date !== '' ? data.effective_to_date : '-'}
                      </Typography>
                      <br />
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        Create by :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {data.create_by !== '' ? data.create_by : '-'}
                      </Typography>
                      <br />
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        Create date :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {data.create_date !== '' ? data.create_date : '-'}
                      </Typography>
                      <br />
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        Updated by :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {data.update_by !== '' ? data.update_by : '-'}
                      </Typography>
                      <br />
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        Updated date :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {data.update_date !== '' ? data.update_date : '-'}
                      </Typography>
                      <br />
                      <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                        Division In Charge :
                      </Typography>
                      &nbsp;
                      <Typography display="inline" style={{ marginLeft: 10 }}>
                        {data.division_id !== '' ? `(${data.division_id}) ${data.division_name}` : '-'}
                      </Typography>
                      <br />
                      {data?.type_status === 'deleted' ? (
                        <>
                          {/* show this section if the type is deleted */}
                          <br />
                          <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                            Division in charge :
                          </Typography>
                          &nbsp;
                          <Typography display="inline" style={{ marginLeft: 10 }}>
                            {data.division_id !== '' ? `(${data.division_id}) ${data.division_name}` : '-'}
                          </Typography>
                          <br />
                          <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                            Type Name :
                          </Typography>
                          &nbsp;
                          <Typography display="inline" style={{ marginLeft: 10 }}>
                            {data.type_name !== '' ? data.type_name : '-'}
                          </Typography>
                          <br />
                          <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                            Status :
                          </Typography>
                          &nbsp;
                          <Typography display="inline" style={{ marginLeft: 10 }}>
                            {data.type_status !== '' ? data.type_status : '-'}
                          </Typography>
                          <br />
                          <Typography style={{ marginLeft: 10 }} variant="h5" display="inline">
                            Division In Charge :
                          </Typography>
                          &nbsp;
                          <Typography display="inline" style={{ marginLeft: 10 }}>
                            {data.division_id !== '' ? `(${data.division_id}) ${data.division_name}` : '-'}
                          </Typography>
                        </>
                      ) : (
                        <>
                          {/* show this section if the type is NOT deleted */}
                          <TextField
                            error={Boolean(touched.typeName && errors.typeName)}
                            fullWidth
                            helperText={touched.typeName && errors.typeName}
                            label="Type Name"
                            margin="normal"
                            name="typeName"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            onChangeCapture={() => {
                              // setTempTypeName(e.target.value);
                            }}
                            value={values.typeName}
                            variant="outlined"
                          />
                          <TextField
                            error={Boolean(touched.typeStatus && errors.typeStatus)}
                            fullWidth
                            helperText={touched.typeStatus && errors.typeStatus}
                            label="Select status"
                            margin="normal"
                            name="typeStatus"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            onClick={() => {
                              // setTempTypeStatus(e.target.value);
                            }}
                            select
                            value={values.typeStatus}
                            variant="outlined"
                          >
                            <MenuItem value="active">
                              <em>Active</em>
                            </MenuItem>
                            <MenuItem value="inactive">
                              <em>Inactive</em>
                            </MenuItem>
                          </TextField>
                          <br />
                          <Box my={2}>
                            <Button
                              color="primary"
                              disabled={isSubmitting}
                              fullWidth
                              size="large"
                              type="submit"
                              variant="contained"
                            >
                              UPDATE
                            </Button>
                          </Box>
                        </>
                      )}
                    </>
                  )}
                </form>
              )}
            </Formik>
          </Container>
        )}
      </Box>
    </Page>
  );
};

EquipmentTypeDetails.propTypes = {
  employeeNumber: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.bool,
  divisions: PropTypes.array,
  openPopup: PropTypes.bool,
  setOpenPopup: PropTypes.func,
  accountType: PropTypes.string,
  // employeeDivision: PropTypes.string,
  data: PropTypes.object,
};

export default EquipmentTypeDetails;
