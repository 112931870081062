import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles,
  MenuItem,
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import {
  addEquipmentPackage,
  listOfEquipmentType,
  listOfAllEquipmentType
} from '../../../actions/equipment';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const EquipmentPackageForm = ({
  employeeNumber,
  refresh,
  setRefresh,
  accountType,
  openPopup,
  setOpenPopup,
  employeeDivision
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const [tempTypeId, setTempTypeId] = useState('');
  const [tempPackageStatus, setTempPackageStatus] = useState('');
  const [tempPackageName, setTempPackageName] = useState('');
  const [equipmentTypeList, setEquipmentTypeList] = useState([]);
  const [tempInitialValue, setTempInitialValue] = useState({
    createBy: employeeNumber,
    typeId: '',
    packageStatus: '',
    packageName: '',
  });

  const handleTypeClick = (type) => {
    setTempTypeId(type?.TYPE_ID);
  };

  const filterEquipmentTypeList = (list) => {
    const tempArrayList = [];
    list.map((row) => {
      if (row.TYPE_STATUS !== 'deleted') {
        tempArrayList.push({
          CREATE_BY: row.CREATE_BY,
          CREATE_DATE: row.CREATE_DATE,
          DIVISION_ID: row.DIVISION_ID,
          DIVISION_NAME: row.DIVISION_NAME,
          EFFECTIVE_FROM_DATE: row.EFFECTIVE_FROM_DATE,
          EFFECTIVE_TO_DATE: row.EFFECTIVE_TO_DATE,
          TYPE_ID: row.TYPE_ID,
          TYPE_NAME: row.TYPE_NAME,
          TYPE_STATUS: row.TYPE_STATUS,
          UPDATE_BY: row.UPDATE_BY,
          UPDATE_DATE: row.UPDATE_DATE
        });
      }
      return null;
    });
    return tempArrayList;
  };

  const retrieveData = () => {
    if (accountType === 'Super Admin') {
      dispatch(listOfAllEquipmentType())
        .then((res) => {
          if (res?.success === true) {
            setEquipmentTypeList(filterEquipmentTypeList(res?.equipmentType) || []);
          }
        });
    } else if (accountType === 'Division Admin') {
      dispatch(listOfEquipmentType(employeeDivision))
        .then((res) => {
          if (res?.success === true) {
            setEquipmentTypeList(filterEquipmentTypeList(res?.equipmentType) || []);
          }
        });
    }
  };

  useEffect(() => {
    setTempInitialValue({
      ...tempInitialValue,
      typeId: tempTypeId,
      packageStatus: tempPackageStatus,
      packageName: tempPackageName,
    });
  }, [
    tempTypeId,
    tempPackageStatus,
    tempPackageName
  ]);

  useEffect(() => {
    retrieveData();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Add Equipment Package"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            enableReinitialize
            initialValues={tempInitialValue}
            validationSchema={
              Yup.object().shape({
                packageName: Yup.string().max(149).ensure().required('Please input package name'),
                packageStatus: Yup.string().ensure().required('Please select status'),
                typeId: Yup.string().ensure().required('Please select type'),
              })
            }
            onSubmit={
              (values, { setSubmitting }) => {
                console.log(values);
                dispatch(addEquipmentPackage(values))
                  .then((res) => {
                    if (res?.success === true) {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setRefresh(!refresh);
                      setOpenPopup(!openPopup);
                    } else {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setSubmitting(false);
                    }
                  })
                  .catch();
              }
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Typography
                  color="primary"
                  style={{ fontWeight: 500 }}
                >
                  Add New Package
                </Typography>
                <TextField
                  error={Boolean(touched.typeId && errors.typeId)}
                  fullWidth
                  helperText={touched.typeId && errors.typeId}
                  label="Select equipment type to add package"
                  margin="normal"
                  name="typeId"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  select
                  value={values.typeId}
                  variant="outlined"
                >
                  {equipmentTypeList?.length !== 0 ? (
                    equipmentTypeList.map((type) => (
                      <MenuItem
                        value={type.TYPE_ID}
                        key={type.TYPE_ID}
                        onClick={() => handleTypeClick(type)}
                        style={{ whiteSpace: 'unset' }}
                      >
                        {`(${type.DIVISION_NAME}) [${type.TYPE_STATUS?.toUpperCase()}] ${type.TYPE_NAME}`}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                  )}
                </TextField>
                <TextField
                  error={Boolean(touched.packageName && errors.packageName)}
                  fullWidth
                  helperText={touched.packageName && errors.packageName}
                  label="Package Name"
                  margin="normal"
                  name="packageName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onChangeCapture={(e) => {
                    setTempPackageName(e.target.value);
                  }}
                  value={values.typeName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.packageStatus && errors.packageStatus)}
                  fullWidth
                  helperText={touched.packageStatus && errors.packageStatus}
                  label="Select package status"
                  margin="normal"
                  name="packageStatus"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  onClick={(e) => {
                    setTempPackageStatus(e.target.value);
                  }}
                  select
                  value={values.typeStatus}
                  variant="outlined"
                >
                  <MenuItem value="active">
                    <em>Active</em>
                  </MenuItem>
                  <MenuItem value="inactive">
                    <em>Inactive</em>
                  </MenuItem>
                </TextField>
                <br />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    ADD
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

EquipmentPackageForm.propTypes = {
  employeeNumber: PropTypes.string,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.bool,
  openPopup: PropTypes.bool,
  setOpenPopup: PropTypes.func,
  accountType: PropTypes.string,
  employeeDivision: PropTypes.string,
};

export default EquipmentPackageForm;
