import React, { useState } from 'react';
import { /* Link as RouterLink,  */useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Captcha from 'demos-react-captcha';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
// import { Alert } from '@material-ui/lab';
import Page from 'src/components/Page';
import { useDispatch } from 'react-redux';
import { forgotpassword as Forgotpassword } from '../../actions/auth';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../actions/notifier';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    // height: '100%',
    // paddingBottom: theme.spacing(3),
    // paddingTop: theme.spacing(3)
  }
}));

const ForgotPasswordView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useNavigate();
  // const [error, setError] = useState(false);
  // const [message, setMessage] = useState(null);
  const [captchabox, setCaptchabox] = useState(false);
  const [test, setTest] = useState(false);
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  /* const handleError = (val) => {
    setMessage(val);
    setError(true);
  }; */

  const handlecaptchabox = () => {
    setCaptchabox((prev) => !prev);
    setTest(false);
  };

  const onChange = (value) => {
    setTest(value);
  };
  const onRefresh = () => {
    setTest(false);
  };

  return (
    <Page
      className={classes.root}
      title="Forgot Password"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              employee_number: ''
            }}
            validationSchema={
              Yup.object().shape({
                email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                employee_number: Yup.number().max(999999).positive().min(2, 'Must be more than 1 character')
                  .required('Employee Number is required'),
                captchabox: Yup.boolean().oneOf([true], 'This field must be checked')
              })
            }
            onSubmit={
              (values, { setSubmitting }) => {
                dispatch(Forgotpassword(values))
                  .then((res) => {
                    if (res?.success === true) {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      history('/login');
                    } else {
                      enqueueSnackbar({
                        message: res?.message,
                        options: {
                          key: new Date().getTime() + Math.random(),
                          action: (key) => (
                            <Button onClick={() => closeSnackbar(key)}>Dismiss me</Button>
                          ),
                          success: res?.success,
                          notifier: res?.notifier || null,
                        },
                      });
                      setSubmitting(false);
                    }
                  })
                  .catch();
              }
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  pt={3}
                  display="flex"
                  justifyContent="center"
                >
                  <Typography
                    color="primary"
                    variant="h2"
                  >
                    Forgot Password
                  </Typography>
                </Box>
                <br />
                <br />
                <Box
                  m={2}
                  pb={5}
                >
                  <Box
                    pt={3}
                  >
                    <Typography
                      color="textPrimary"
                      variant="h4"
                    >
                      User Information
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      error={Boolean(touched.employee_number && errors.employee_number)}
                      fullWidth
                      helperText={touched.employee_number && errors.employee_number}
                      label="Employee Number"
                      margin="normal"
                      name="employee_number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="number"
                      value={values.employee_number}
                      variant="outlined"
                    />
                    <Typography>
                      <Box mt={2}>
                        Enter the email address you used to register before
                      </Box>
                    </Typography>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label="Email Address"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                    />
                    <Box
                      alignItems="center"
                      display="flex"
                      ml={-1}
                    >
                      <Checkbox
                        name="captchabox"
                        onClick={handlecaptchabox}
                      />
                      <Typography
                        color="textSecondary"
                        variant="body1"
                      >
                        I am not a robot
                      </Typography>
                    </Box>
                    {captchabox ? (
                      <>
                        <Captcha onChange={onChange} placeholder="Enter captcha" onRefresh={onRefresh} />
                      </>
                    ) : null}
                    {Boolean(touched.captchabox && errors.captchabox) && (
                      <FormHelperText error>
                        {errors.captchabox}
                      </FormHelperText>
                    )}
                    {/* {error && (
                      <>
                        <Alert severity="error">{message}</Alert>
                      </>
                    )} */}
                    <Box my={2}>
                      <Button
                        color="primary"
                        disabled={test === false}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Reset My Password
                      </Button>
                    </Box>
                  </Box>

                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default ForgotPasswordView;
